.DayPicker {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0980392156862745);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0980392156862745);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0980392156862745);
}

.DayPicker-Month {
    width: 100%;
}

.DayPicker-Caption {
    text-align: center;
}

.DayPicker-Caption>div {
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    color: #220D89;
    text-align: center;
    line-height: normal;
}

.DayPicker-Day--highlighted {
    background-color: #220d89;
    border-radius: 4px;
    color: #fff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    background-color: #f84a3d!important
}