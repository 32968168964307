.my--message,
.income--message{
    position: relative;
    padding: 15px;
    margin-bottom: 15px;
}

.my--message::after{
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    right: -15px;
    position: absolute;
    border-bottom: 15px solid #dae2fa;
    border-right: 15px solid transparent;
}

.income--message::after{
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    left: -15px;
    position: absolute;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;;
}