.page--wrapper {
  /* height: calc(100vh - 128px); */
  max-width: 80%;
  min-width: 340px;
  margin: 0 auto;
  background-color: #f0f6ff;
  padding-top: 10px;
  padding-bottom: 82px;
}

.page--wrapper--no-margin {
  min-width: 340px;
  margin: 0 auto;
  background-color: #f0f6ff;
  padding-bottom: 82px;
  position: relative;
}

.page--wrapper-2 {
  /* overflow-y: auto; */
  min-height: calc(100vh - 177px);
  min-width: 340px;
  margin: 0 auto;
  background-color: #f0f6ff;
  max-width: 80%;
}

.page--wrapper--messages {
  overflow-y: auto;
  height: calc(100vh - 176px);
  max-width: 80%;
  min-width: 340px;
  margin: 0 auto;
  background-color: #f0f6ff;
}

.page--wrapper--conference {
  min-width: 340px;
  margin: 0 auto;
  background-color: #f0f6ff;
  /* padding-bottom: 82px; */
}

.react-daypicker-root {
  background-color: #fff;
  margin: 0 auto;
  border-radius: 4px;
  max-width: auto;
}

.loading__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-image: url('../src/images/qaseeffect1.png');
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #220d89;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 11001;
}

.loading__wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: url('../src/images/qaseeffect1.png') 0 0 repeat;
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.processing__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 11001;
}

button {
  outline: none!important;
}

.StripeElement {
  display: block;
  padding: 10px 0;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  outline: 0;
  background: white;
  position: relative;
}

.StripeElement:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.StripeElement:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #303f9f;
  pointer-events: none;
}

.StripeElement:hover::before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.StripeElement--focus::after {
  transform: scaleX(1);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

/* Buttons */

.btn-success {
  color: #fff;
  background-color: #00cc9b;
  border-color: #00cc9b;
  outline: none;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  background-color: #00cc9b;
  border-color: #00cc9b;
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success:hover {
  color: #fff;
  background-color: #00cc9b;
  border-color: #00cc9b;
  -webkit-box-shadow: 0px 3px 0px #00f0b5 !important;
  -moz-box-shadow: 0px 3px 0px #00f0b5 !important;
  box-shadow: 0px 3px 0px #00f0b5 !important;
}

.react-datepicker-popper {
  z-index: 200;
}