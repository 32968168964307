.calendar-header {
    position: relative;
}

.calendar-navigation {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.calendar-button-wrapper {
    position: absolute;
}

.calendar-button-wrapper.button-prev {
    left: 0;
}

.calendar-button-wrapper.button-next {
    right: 0;
}

.calendar-weeks {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 5px 0;
}

.calendar-row {
    margin: 10px 0;
}

.calendar-row span {
    color: #220d89;
    display: inline-block;
    width: 100%;
}

.calendar-row span.selected-time {
    background: #220d89;
    color: #fff;
    border-radius: 4px;
}