.page-login__wrapper {
    height: 100vh;
}

.page-login__wrapper::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.02;
    background-color: #f0f6ff;
    background-image: url('../images/logo-blue.png');
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

.page_login__content {
    min-width: 300px;
    margin: 0 auto;
    max-width: 80%;
    padding-top: 10px;
}