.home-page__wrapper {
    height: 100vh; /*calc(100vh - 64px);*/
    background-image: url('../images/qaseeffect1.png');
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #220d89;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.home-page__wrapper:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: url(../images/qaseeffect1.png) 0 0 repeat;
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.home-page__content {
    max-width: 300px;
    z-index: 1;
}